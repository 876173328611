import React from 'react';
import Container from '@material-ui/core/Container';
import MailRoundedIcon from '@material-ui/icons/MailRounded';

class Footer extends React.Component {

    render(){

        return  <div className="fixed-bottom">
                    <div className="footer">
                        <Container>
                            <div className="footer-content">
                                <span>¿Alguna sugerencia?&nbsp;</span>
                                <a className="mail-icon" target="_blank" rel="noopener noreferrer"
                                   href="mailto:info@makinarecords.com" >
                                    <MailRoundedIcon />
                                </a>
                            </div>
                        </Container>
                    </div>
                </div>
    }

}

export default Footer;
