import React from 'react';

import Card from '@material-ui/core/Card';

import { API_ROOT, CACHE_VERSION, HOST_ROOT } from '../../api-config';

import Player from '../Player/player';
import Container from '@material-ui/core/Container';

import BottomScrollListener from 'react-bottom-scroll-listener';

class Sessions extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            sessions: [],
            sessions_showed: [],
            sessionPlaying: 0,
            dataFilter: '',
            error: null,
            isLoading: false,
            isFetched: false
        };

        this.isCacheAvailable = 'caches' in window;


    }

    componentDidMount() {

        this.setState( {isLoading: true} );

        // console.log( API_ROOT );

        fetch( API_ROOT+'sessions.php' )

            .then( response => {

                //response.json()

                if( this.isCacheAvailable ){

                    // OPEN AND SAVING AND LOADING THE SESSIONS IN/FROM CACHE

                    window.caches.open( CACHE_VERSION ).then((cache) => {

                        cache.put( HOST_ROOT+this.state.url, response ).then( () => {

                            cache.match( HOST_ROOT+this.state.url ).then((response) => {

                                if( response!==undefined ){

                                    response.json().then((data) => {

                                        let size = data.length;

                                        this.setState({ sessions:data.slice(9,size),
                                            sessions_showed:data.slice(0,9), isLoading:false });

                                    });
                                }
                            })

                        });
                    });
                }
            } )
            .catch( error => {

                // NO NETWORK

                this.setState({ error:error, isLoading:false });

                window.caches.open( CACHE_VERSION ).then((cache) => {

                    cache.match( HOST_ROOT+this.state.url ).then((response) => {

                        if( response!==undefined ){

                            response.json().then((data) => {

                                let size = data.length;

                                this.setState({ sessions:data.slice(9,size),
                                    sessions_showed:data.slice(0,9), isLoading:false });

                            });
                        }
                    })
                });
            })

    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        // PROPS MEANS THE DATA FILTER (IS THE ONE AT THE MOMENT)

        const dataToFilterWords = nextProps.dataFilter.trim().toString().toLowerCase().split(' ');

        let sessions = this.state.sessions_showed;

        if( dataToFilterWords.length === 1 && dataToFilterWords[0].trim() === '' ){

            // Filter is empty

            sessions = this.showAllSessions(sessions);

        }else{

            let counterMatches = 0;

            for( const session of sessions ) {

                if( this.state.sessionPlaying !== session.id ){

                    session.filtered = true;

                    if( !this.isSessionFiltered( session, dataToFilterWords ) ){
                        session.filtered = false;
                        counterMatches++;
                    }
                }
            }

            if( counterMatches < 10 ){

                let more_sessions = this.state.sessions;
                let i=0;

                while( i < more_sessions.length && counterMatches < 11 ) {

                    if( !this.isSessionFiltered( more_sessions[i], dataToFilterWords ) ){

                        sessions = sessions.concat( more_sessions.splice(i,i+1) );
                        counterMatches++;

                    }

                    i++;
                }

            }
        }

        this.setState({ sessions_showed: sessions,
            dataFilter: nextProps.dataFilter });

    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        // PROPS MEANS THE DATA FILTER (IS THE ONE AT THE MOMENT)

        const dataToFilterWords = nextProps.dataFilter.trim().toString().toLowerCase().split(' ');

        let sessions = this.state.sessions_showed;

        if( dataToFilterWords.length === 1 && dataToFilterWords[0].trim() === '' ){

            // Filter is empty

            sessions = this.showAllSessions(sessions);

        }else{

            let counterMatches = 0;

            for( const session of sessions ) {

                if( this.state.sessionPlaying !== session.id ){

                    session.filtered = true;

                    if( !this.isSessionFiltered( session, dataToFilterWords ) ){
                        session.filtered = false;
                        counterMatches++;
                    }
                }
            }

            if( counterMatches < 10 ){

                let more_sessions = this.state.sessions;
                let i = 0;

                while( i < more_sessions.length && counterMatches < 11 ) {

                    if( !this.isSessionFiltered( more_sessions[i], dataToFilterWords ) ){

                        sessions = sessions.concat( more_sessions.splice(i,i+1) );
                        counterMatches++;

                    }

                    i++;
                }

            }
        }

        this.setState({ sessions_showed: sessions,
            dataFilter: nextProps.dataFilter });

    }


    isSessionFiltered( session, filterText ){

        let counterMatches = 0;
        let ret = true;

        for( const dataToFilter of filterText ){

            if( dataToFilter.length > 0 ){

                if( session.name_disco.length > 0
                    && session.name_disco.toString().toLowerCase().includes(dataToFilter)) {
                    counterMatches++;
                    continue;
                }

                if( session.description.length > 0
                    && session.description.toString().toLowerCase().includes(dataToFilter)) {
                    counterMatches++;
                    continue;
                }

                if( session.year != null && session.year.toString().toLowerCase().length > 0
                    && session.year.toString().includes(dataToFilter)) {
                    counterMatches++;
                    continue;
                }

                for( const artist of session.artists ){

                    if( artist.name.toString().toLowerCase().includes(dataToFilter) ){
                        counterMatches++;
                        continue;
                    }
                }
            }
        }

        if( counterMatches===filterText.length ){
            ret = false;
        }

        return ret;

    }

    showAllSessions( sessions ){

        for( const session of sessions ){

            if( session.filtered ){
                session.filtered = false;
            }
        }

        return sessions;

    }

    setSessionPlaying = (dataFromChild) => {

        this.setState({ sessionPlaying: dataFromChild });

    };

    handleScroll = () => {

        const dataToFilterWords = this.state.dataFilter.trim().toString().toLowerCase().split(' ');

        let sessions = this.state.sessions;
        let sessions_showed = this.state.sessions_showed;

        if( sessions.length > 0 ){

            let iterator = 0, counter = 0;

            while( counter < 11 && iterator < sessions.length ){

                if( dataToFilterWords.length === 1 && dataToFilterWords[0].trim() === '' ) {

                    sessions_showed = sessions_showed.concat( sessions.splice(iterator, 1) );
                    counter++;
                    iterator--;

                }else{

                    if( !this.isSessionFiltered( sessions[iterator], dataToFilterWords ) ){
                        sessions_showed = sessions_showed.concat( sessions.splice(iterator, 1) );
                        counter++;
                        iterator--;
                    }

                }

                iterator++;
            }

            this.setState({ sessions:sessions, isFetched: (sessions.length==0)?true:false,
                sessions_showed:sessions_showed });
        }

    };


    render() {
        const {sessions_showed, sessionPlaying, isLoading, isFetched, error} = this.state;
        return <Container><BottomScrollListener
                            onBottom={this.handleScroll}
                            offset={300}>
                    <div className={sessionPlaying>0?'sessions_container playing':'sessions_container'} >

                    {isLoading && <h4>Is loading</h4>}

                    {error && sessions_showed.length===0 && <p>{error.message}</p>}

                    {sessions_showed.map((session) =>

                    <Card key={session.id} className={session.id===sessionPlaying?'session_card playing':'session_card'}
                            style={ (!session.filtered?{display:'block'}:{display:'none'}) } >

                        <Player
                            callbackFromParent={this.setSessionPlaying}
                            session={session}
                            session_playing={sessionPlaying} >
                        </Player>

                    </Card>
                    )}

                    {isFetched && <p id="sessions-bottom" >No more results.</p>}

                </div>
            </BottomScrollListener></Container>
    }
}

export default Sessions;