let backendHost = '';
let hostName = '';

const hostname = window && window.location && window.location.hostname;

if( hostname === 'localhost' ) {
    hostName = 'http://localhost:3000/';
    backendHost = 'http://api-makineta.local/';

}else{
    hostName = 'https://makinarecords.com/';
    backendHost = 'https://makinarecords.com/api/';
}

export const API_ROOT = backendHost;
export const HOST_ROOT = hostName;
export const CACHE_VERSION = 'my-cache';