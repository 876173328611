import React from "react";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudOff from '@material-ui/icons/CloudOff';
import { HOST_ROOT, CACHE_VERSION } from '../../api-config';
import CircularProgress from '@material-ui/core/CircularProgress';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import WaveSurfer from 'wavesurfer.js';
import Jsmediatags from 'jsmediatags';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


class Player extends React.Component {

    constructor(props) {

        super(props);

        this.id_session = this.props.session.id;

        this.state = {
            play: false,
            url: this.props.session.file,
            audio_current_time: 0,
            audio_duration: 0,
            buffered: null,
            isCaching: false,
            isCached: null,
            isLoading: true,
            isPeaked: false,
            audio_image: ''
        };

        this.audio = new Audio();

        console.log(  );

        this.isCacheAvailable = 'caches' in window;

        if( this.isCacheAvailable ){

            window.caches.open(CACHE_VERSION).then((cache) => {

                cache.match( HOST_ROOT+'music/'+this.state.url ).then((response) => {

                    if( response!==undefined ){

                        response.blob().then((myBlob) => {

                            this.setState({ isCached:true });
                            this.audio.src = URL.createObjectURL(myBlob);

                        });

                    }else{

                        this.audio.src = 'music/'+this.state.url;
                    }

                })
            });

        }else{

            // caches navigator NOT SUPPORTED YET

            this.audio.src = HOST_ROOT+'/music/'+this.state.url;
            // this.setState({ isLoading:false });

        }


        // IMAGE TAGS LECTURE

        if( window.navigator.onLine ){

            Jsmediatags.read( HOST_ROOT+'/music/'+this.state.url , {

                onSuccess: (tag) => {

                    if( tag.tags.picture ){

                        let base64String = "";

                        for( let i = 0; i < tag.tags.picture.data.length; i++ ){
                            base64String += String.fromCharCode(tag.tags.picture.data[i]);
                        }

                        let dataUrl = "data:" + tag.tags.picture.format + ";base64," + window.btoa(base64String);

                        this.setState({audio_image:dataUrl});

                        // SAVING IMAGE SESSION IN CACHE

                        fetch( dataUrl )
                            .then(response => {

                                if( this.isCacheAvailable ) {

                                    window.caches.open(CACHE_VERSION).then((cache) => {

                                        cache.put(HOST_ROOT+'music/'+this.state.url+'image', response);

                                    });
                                }

                            });
                    }
                },

                onError: function(error){

                    console.log(':(', error.type, error.info);

                }
            });

        }else{

            // TRYING TO LOAD IMAGES THAT ARE IN CACHE

            if( this.isCacheAvailable ){

                window.caches.open(CACHE_VERSION).then((cache) => {

                    cache.match( HOST_ROOT + 'music/' + this.state.url + 'image' ).then((response) => {

                        if( response!==undefined ){

                            this.setState({audio_image:response.url});

                        }

                    })
                });
            }
        }
    }

    togglePlay = () => {

        if( !this.state.isLoading ){

            if( this.state.play ){

                document.title = 'Makina Records';

                this.audio.pause();
                this.setState({ play:false });
                this.props.callbackFromParent( 0 );

            }else{

                document.title = this.setTitlePage();

                this.audio.play();
                this.setState({ play:true });
                this.props.callbackFromParent( this.id_session );

            }

        }

    };

    componentDidMount(){

        this.audio.addEventListener('loadedmetadata', (data) => {

            this.setState({isLoading:false, audio_current_time:this.audio.currentTime, audio_duration:this.audio.duration});

        });

        this.audio.addEventListener("timeupdate", () => {

            this.setState({audio_current_time:this.audio.currentTime});

            if( !this.state.isPeaked ){
                this.positionHandle( this.audio.currentTime*this.timeline.offsetWidth/this.audio.duration );
            }

        });


        this.audio.addEventListener('progress', () => {

            this.fillBuffered();

        });


        // WAVESURFERS CREATION

        if( parseInt(this.props.session.peaked)===1 && 1==2 ){

            const nameFile = this.state.url.split('.');

            fetch('music/peaks/'+nameFile[0]+'.json')
                .then(response => {
                    return response.json();
                })
                .then(peaks => {

                    this.setState({ isPeaked:true });

                    // load peaks into wavesurfer.js
                    this.wavesurfer = WaveSurfer.create({
                        barWidth:1.2,
                        container:'#waveform' + this.id_session,
                        backend:'MediaElement',
                        height:30,
                        progressColor:'#4a74a5',
                        responsive:true,
                        waveColor:'#ccc',
                        cursorWidth:2,
                        cursorColor:'#4a74a5',
                        plugins: [
                            WaveSurferCursor.create({
                                showTime: true,
                                opacity: 1,
                                customShowTimeStyle: {
                                    'background-color':'#4a74a5',
                                    color: '#fff',
                                    padding: '2px',
                                    'font-size': '11px'
                                },
                                formatTimeCallback: this.formatTime
                            })
                        ]
                    });

                    this.wavesurfer.load(this.audio, peaks.data);

                    this.wavesurfer.on('ready', () => {
                        this.setState({ isLoading:false });
                        this.wavesurfer.empty();
                        this.wavesurfer.drawBuffer();
                    });

                })
                .catch((e) => {
                    console.error('error', e);
                    this.setState({ isPeaked:false });
                    this.setState({ isLoading:false });
                });

        }else{

            this.setState({ isPeaked:false });
            // this.setState({ isLoading:false });
        }

        // END WAVESURFER CREATION


    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( this.id_session!==nextProps.session_playing && this.state.play){

            this.audio.pause();
            this.setState({ play:false });

        }

    }

    printArtists( artists ){

        let ret = [];

        artists.forEach(function(artist){
            ret.push(<div className="artist" key={artist.id}>{artist.name}</div>);
        });

        return ret;

    }

    positionHandle = (position) => {

        if( position >= 0 && position <= this.timeline.offsetWidth ){
            this.handle.style.paddingLeft = position + "px";
        }

        if( position < 0 ){
            this.handle.style.paddingLeft = "0px";
        }

        if( position > this.timeline.offsetWidth ){
            this.handle.style.paddingLeft = position + "px";
        }
    };

    downloadFile = () => {

        let a = document.createElement('a');

        a.href = 'music/'+this.state.url;
        a.download = 'music/'+this.state.url+'.mp3';
        a.click();

    };

    mouseMove = (e) => {

        const current_time = (e.pageX-this.getElementOffset(this.timeline).left) * this.audio.duration / this.timeline.offsetWidth;

        if( !isNaN(current_time) ){

            this.audio.currentTime = current_time;

            this.setState({audio_current_time:current_time});
        }

    };

    getElementOffset(el) {
        const rect = el.getBoundingClientRect();

        return {
            top: rect.top + window.pageYOffset,
            left: rect.left + window.pageXOffset,
        };
    }

    mouseDown = () => {

        window.addEventListener('mousemove', this.mouseMove);
        window.addEventListener('mouseup', this.mouseUp);

    };

    mouseUp = () => {

        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('mouseup', this.mouseUp);

    };

    formatTime(time){

        let hours = 0;

        let minutes = parseInt(time / 60, 10);
        let seconds = parseInt(time % 60);

        if( minutes>=60 ){
            hours = parseInt( minutes / 60 );
            minutes = minutes-60*hours;
        }

        return (hours>0?hours+':':'')+(minutes<10?'0'+minutes:minutes)+':'+(seconds<10?'0'+seconds:seconds);
    }

    formatDisco( discoName ){

        let ret = '';
        if( discoName.length>0 ){

            ret += discoName;

        }
        return ret;

    }

    printSoundPunctuation( punctuation ){

        let ret = [];
        let resto = 0;

        for( let i=1;i<=punctuation;i++ ){
            ret.push(<StarIcon key={i.toString()} fontSize="small"/>);
            resto++;
        }

        if( resto<punctuation ){
            ret.push(<StarHalfIcon key={punctuation} fontSize="small"/>);
        }

        return ret;

    }

    setTitlePage(){

        const session = this.props.session;

        let ret = '';

        // disco name format
        let namedisco = session.name_disco.split(' ');
        namedisco.forEach(function(word){
            if(word==='Discoteca'){return}
            ret += word.charAt(0).toUpperCase()+''+word.slice(1).toLowerCase();
        });

        let Months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto',
            'Septiembre','Octubre','Noviembre','Diciembre'];

        if( session.day>0 || session.month>0 || session.year>0 ){
            ret += '-';
        }

        if( session.day>0 ){
            ret += session.day;
        }
        if( session.month>0 ){
            ret += Months[session.month-1];
        }
        if( session.year>0 ){
            ret += session.year;
        }

        let i = 0;
        session.artists.forEach(function(artist){
            ret += (i===0)?'-':'&';
            ret += artist.name;
            i++;
        });

        if( session.description.length>0 ){

            ret += '-';
            let description = session.description.split(' ');
            description.forEach(function(word){
                ret += word.charAt(0).toUpperCase()+''+word.slice(1).toLowerCase();
            });
        }

        return ret;

    }

    formatData(day, month, year){

        let retA = [];
        let Months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto',
            'Septiembre','Octubre','Noviembre','Diciembre'];

        let ret = '';
        if(day>0){
            ret += day+'-';
        }
        if(month>0){
            ret += Months[month-1]+'-';
        }
        if(year>0){
            ret += year;
        }

        if( ret.length>0 ){
            retA.push(<div key={day+''+month+''+year} >{ret}</div>);
        }

        return retA;
    }

    fillBuffered(){

        /* ONLY FOR TESTING
        if( this.audio.buffered.length>0 && this.state.audio_duration>0 ) {
            for( let counter = 0;counter<this.audio.buffered.length;counter++ ){

                console.log(this.audio.buffered.start(counter)+' - '+this.audio.buffered.end(counter));

            }
        }
        */

        let ret = [];

        if( this.audio.buffered.length>0 && this.state.audio_duration>0 ) {
            for( let counter=0;counter<this.audio.buffered.length;counter++ ){
                let buffer = [];
                buffer.id = counter;
                buffer.width = (this.audio.buffered.end(counter) - this.audio.buffered.start(counter)) * this.timeline.offsetWidth / this.state.audio_duration;
                buffer.left = this.audio.buffered.start(counter) * this.timeline.offsetWidth / this.state.audio_duration;
                ret.push(buffer);
            }
        }

        this.setState({buffered:ret});

    }

    addCacheFile = () => {

        this.setState({ isCaching:true });

        fetch( HOST_ROOT+'music/'+this.state.url )
            .then( response =>  {

                // OPEN AND SAVING AND LOADING BLOB IN/FROM CACHE

                window.caches.open('my-cache').then((cache) => {

                    cache.put( HOST_ROOT+'music/'+this.state.url, response ).then( () => {

                        this.setState({ isCaching:false });

                        cache.match( HOST_ROOT+'music/'+this.state.url ).then((response) => {

                            if( response!==undefined ){

                                response.blob().then((myBlob) => {

                                    this.setState({ isCached:true });

                                    this.audio.src = URL.createObjectURL(myBlob);

                                });
                            }
                        })
                    });
                });
            })
            .catch( error => {

                console.log(error);

                this.setState({ isCaching:false });

            })

    }

    deleteCacheFile = () => {

        window.caches.open('my-cache').then((cache) => {

            cache.delete( HOST_ROOT+'music/'+this.state.url ).then( () => {

                this.setState({ isCached:false });
                this.audio.src = 'music/'+this.state.url;

            });
        });

    }

    render() {
        const {audio_current_time, audio_duration, isPeaked, buffered,
            isCaching, isCached, isLoading, audio_image} = this.state;
        const id_session = this.id_session;
        const session = this.props.session;

        ////// PUNCTUATION REMOVED
        ////// <div className="punctuation-content">
        ////// {this.printSoundPunctuation(session.sound)}
        ////// </div>

        return (
            <div className="player-content" >

                {audio_image.length>0 && <div className="image-file-desktop">
                                                    <img alt={this.setTitlePage()} src={audio_image} />
                                                </div>}

                <div className="player" >

                    <div className="player-header">

                        {audio_image.length>0 && <div className="image-file-mobile">
                            <img alt={this.setTitlePage()} src={audio_image} />
                        </div>}

                        <div onClick={this.togglePlay}
                             className="btn_play"
                             aria-label="play">
                            {this.state.play?<PauseCircleFilledIcon />:<PlayCircleFilledIcon />}

                        </div>

                        <div className="card_header">

                            <div>
                                <div className="card-info" >
                                    {this.formatData(session.day, session.month, session.year)}
                                    {this.printArtists(session.artists)}
                                </div>
                                <h3>

                                    {this.formatDisco(session.name_disco)}
                                </h3>

                                {session.description.length>0 && <h4>{session.description}</h4>}
                            </div>
                        </div>

                    </div>

                    <div className="progress-bar-container"
                         ref={(timeline) => {this.timeline = timeline}} >

                        {isLoading &&
                            <div className="loader-container" ><CircularProgress size={'20px'} className="off-loader" /></div>
                        }

                        <div className={!isLoading && isPeaked?'progress-bar-waveform':'progress-bar-waveform hidden'}>

                            <div className="buffers">
                                {buffered !== null && buffered.map((buffer) =>
                                    <div key={buffer.id}
                                         style={{width: parseInt(buffer.width), left: parseInt(buffer.left)}}>
                                    </div>
                                )}
                            </div>

                            <div
                                className="waveform"
                                id={'waveform' + id_session}
                                ref={(handle) => {
                                    this.waveform = handle
                                }}
                            />

                        </div>


                        {!isLoading && !isPeaked &&
                            <div className="progress-bar"
                                 onClick={this.mouseMove} >

                                <div className="handler"
                                     ref={(handle) => { this.handle = handle }}
                                     onMouseDown={this.mouseDown}
                                >&nbsp;</div>

                                <div className="buffers">
                                    {buffered!== null && buffered.map((buffer) =>
                                        <div key={buffer.id}
                                             style={{width:parseInt(buffer.width),left:parseInt(buffer.left)}}></div>
                                    )}
                                </div>

                            </div>
                        }

                    </div>

                    <div className="player-bottom">

                        <div className="current-time" >{this.formatTime(audio_current_time)}</div>

                        <div className="buttons">

                            <div onClick={this.downloadFile}
                                 className="btn_download"
                                 aria-label="download">
                                <GetAppIcon
                                    className="download-icon" />
                                <span>Download</span>
                            </div>

                            {!isCached?(

                                <div onClick={this.addCacheFile}
                                     className="btn_to_cache"
                                     aria-label="download">
                                    {isCaching && <CircularProgress className="mini-loader" />}
                                    {!isCaching && <CloudDownloadIcon className="download-icon" />}
                                    <span>Get it offline</span>
                                </div>

                            ):(
                                <div className="btn_cached"
                                     aria-label="downloaded">
                                    <CloudOff
                                        className="download-icon" />
                                    <span>Cached</span>
                                </div>
                            )}

                            {isCached &&
                                <div className="btn_delete_cache"
                                     onClick={this.deleteCacheFile}
                                       aria-label="delete_cache">
                                <HighlightOffIcon
                                    className="delete-icon" />
                                <span>Delete</span>
                            </div>}

                        </div>

                        <div className="audio-duration" >{this.formatTime(audio_duration)}</div>

                    </div>

                </div>

            </div>

        );
    }

}

export default Player;