import React from 'react';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import AlbumIcon from '@material-ui/icons/Album';
import SearchIcon from '@material-ui/icons/Search';
import Container from '@material-ui/core/Container';

class Head extends React.Component {

    constructor(props) {

        super(props);

        this.onChangeInput = this.onChangeInput.bind(this);

    }

    onChangeInput( event ){

        this.props.callbackFromParent( event.target.value );

    }

    render() {

        return <div className="header-content">
                <Container>
                    <div className="header">

                        <div className="header-titles-container">
                            <Icon
                                edge="start"
                                className="header-title-icon"
                                color="inherit"
                                aria-label="open drawer"
                            >
                                <AlbumIcon />
                            </Icon>

                            <Typography className="header-title" variant="h6" noWrap>
                                Makina Records
                            </Typography>

                        </div>

                        <div className="header-search">
                            <div className="header-search-icon">
                                <SearchIcon/>
                            </div>

                            <InputBase
                                placeholder="Buscar…"
                                className="header-search-input"
                                inputProps={{'aria-label':'search'}}
                                onChange={this.onChangeInput}
                            />

                        </div>

                    </div>
                </Container>
            </div>
    }
}

export default Head;
