import React from 'react';
import Head from './components/Head/head';
import Sessions from './components/Sessions/sessions';
import Footer from './components/Footer/footer';
import './App.css';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataFilter:''
        };
    }

    myCallback = (dataFromChild) => {
        // console.log( dataFromChild );
        this.setState({ dataFilter: dataFromChild });
    };

    render() {
        return (
            <div className="App">
                <Head callbackFromParent={this.myCallback}></Head>
                <Sessions dataFilter={this.state.dataFilter} ></Sessions>
                <Footer></Footer>
            </div>
        );
    }
}


export default App;
